import { createContext } from 'react';

export const SidebarContext = createContext<any>(null);

export const Context = createContext<any>(null);

export const CategoryContext = createContext<any>(null);

export const PostAJobContext = createContext<any>(null);

export const DeviceContext = createContext<any>(null);

export const ContentfulContext = createContext<any>(null);

export const IsJobPageContext = createContext<any>(null);
