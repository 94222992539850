import { useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { StyledDiv, Container } from './MainComponent.styles';
import { CategoryContext, Context } from '@/utils/context';
import { useRouter } from 'next/router';
import { MINIMUM_SALARY } from '@/utils/constants';
import { categoriesArray } from '@/blocks/CategoriesBlock/mockData';

const MainContainer = dynamic(() => import(`../MainContainer/MainContainer`));
const SearchBarComponent = dynamic(
  () => import(`../SearchBarComponent/SearchBarComponent`),
);
const CategoriesBlock = dynamic(
  () => import(`@/blocks/CategoriesBlock/CategoriesBlock`),
);

interface Props {
  resetPage: () => void;
}

const MainComponent = ({ resetPage }: Props) => {
  const [tags, setTags] = useState<string[]>([]);
  const [perks, setPerks] = useState<string[]>([]);

  const currentCategory = useContext(CategoryContext);

  const { query } = useRouter() || {};

  const addQuery = (queryTitle: string, queryValue: string) => {
    const url = new URL(window.location.toString());
    const params = Object.fromEntries(url.searchParams.entries());
    if (params[queryTitle] && queryTitle === MINIMUM_SALARY) {
      url.searchParams.set(queryTitle, queryValue);
    } else if (params[queryTitle] && queryTitle !== MINIMUM_SALARY) {
      url.searchParams.set(queryTitle, params[queryTitle] + `,` + queryValue);
    } else {
      url.searchParams.set(queryTitle, queryValue);
    }
    window.history.pushState({}, ``, url);
  };

  const removeQuery = (queryTitle: string, queryValue: string) => {
    const url = new URL(window.location.toString());
    const params = Object.fromEntries(url.searchParams.entries());
    if (queryTitle === MINIMUM_SALARY) {
      url.searchParams.delete(queryTitle);
    } else if (params[queryTitle]) {
      const array = params[queryTitle].split(`,`);
      const filteredArray = array.filter((element) => element !== queryValue);
      if (filteredArray.length) {
        url.searchParams.set(queryTitle, filteredArray.toString());
      } else {
        url.searchParams.delete(queryTitle);
      }
    }
    window.history.pushState({}, ``, url);
  };

  useEffect(() => {
    if (currentCategory && currentCategory.tags) {
      currentCategory.tags.forEach((tag: string) => {
        if (!tags.includes(tag)) {
          setTags((prevState) => {
            return [...prevState, tag];
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  useEffect(() => {
    if (query.tags) {
      const queryTags = query.tags.toString().split(`,`);
      setTags((prevState) => {
        return [...prevState, ...queryTags];
      });
    }
    if (query.perks) {
      const queryPerks = query.perks.toString().split(`,`);
      const newQueryPerks = queryPerks.map((queryPerk) => {
        return `perks:${queryPerk.toUpperCase()}`;
      });
      setPerks((prevState) => {
        return [...prevState, ...newQueryPerks];
      });
    }
  }, [query]);

  return (
    <Context.Provider
      value={{
        tags,
        setTags,
        perks,
        setPerks,
        resetPage,
        addQuery,
        removeQuery,
      }}
    >
      <Container>
        <SearchBarComponent />
        {!currentCategory && (
          <CategoriesBlock categories={categoriesArray} resetPage={resetPage} />
        )}
        <StyledDiv>
          <MainContainer />
        </StyledDiv>
      </Container>
    </Context.Provider>
  );
};

export default MainComponent;
