export const MINIMUM_SALARY = `minimum_salary`;
export const perksArray = [
  { perk: `perks:VISA_SPONSORSHIP`, field: `visa` },
  { perk: `perks:OFFERS_RELOCATION`, field: `offers` },
  { perk: `perks:STARTUP`, field: `startup` },
  { perk: `perks:OPENSOURCE`, field: `opensource` },
  { perk: `perks:PART_TIME`, field: `partTime` },
];

export const compensationArray = [
  // { perk: `perks:PAYS_IN_CRYPTO`, field: `crypto` },
  // { perk: `perks:OFFERS_EQUITY`, field: `equity` },
  { perk: `perks:MINIMUM_ANNUAL_SALARY`, field: MINIMUM_SALARY },
];

//export const EQUITY = `perks:OFFERS_EQUITY`;
//export const CRYPTO = `perks:PAYS_IN_CRYPTO`;
export const MINIMUM_ANNUAL_SALARY = `perks:MINIMUM_ANNUAL_SALARY`;
export const VISA = `perks:VISA_SPONSORSHIP`;
export const RELOCATION = `perks:OFFERS_RELOCATION`;
export const STARTUP = `perks:STARTUP`;
export const OPENSOURCE = `perks:OPENSOURCE`;
export const PART_TIME = `perks:PART_TIME`;

export const COMPANY = `COMPANY`;
export const JOB = `JOB`;
export const CHECKOUT = `CHECKOUT`;

export const CONTENTFUL_ARTICLES_ID = `article`;
export const CONTENTFUL_CATEGORIES_ID = `category`;

export const JOBS_FOUND_SPAN = `jobs-found-span`;
