import styled from 'styled-components';
import { ThemeProps } from '@/styles/global';

export const Container = styled.div`
  min-width: 18.75rem;
`;

export const StyledDiv = styled.div`
  display: flex;
  background-color: ${(props: ThemeProps) => props.theme.whiteColor};
  max-width: 68.75rem;
  margin: auto;
  padding: 0 3.75rem;
  box-sizing: content-box;
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 0 1.875rem;
  }
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;
