import { categoriesLinks } from '@/utils/categories';
import { ICategory } from './CategoriesBlock';

export const categoriesArray: ICategory[] = [
  {
    title: `Javascript`,
    value: categoriesLinks.javascript,
    image: `/categories-logos/javascript.png`,
  },
  {
    title: `React`,
    value: categoriesLinks.react,
    image: `/categories-logos/react.png`,
  },
  {
    title: `Vue.js`,
    value: categoriesLinks.vue,
    image: `/categories-logos/vue.png`,
  },
  {
    title: `Crypto`,
    value: categoriesLinks.crypto,
    image: `/categories-logos/crypto.png`,
  },
  {
    title: `Java`,
    value: categoriesLinks.java,
    image: `/categories-logos/java.png`,
  },
  {
    title: `Python`,
    value: categoriesLinks.python,
    image: `/categories-logos/python.png`,
  },
  {
    title: `DevOps`,
    value: categoriesLinks.devops,
    image: `/categories-logos/devops.png`,
  },
  {
    title: `Android`,
    value: categoriesLinks.android,
    image: `/categories-logos/android.png`,
  },
  {
    title: `IOS`,
    value: categoriesLinks.ios,
    image: `/categories-logos/ios.png`,
  },
  {
    title: `Node\.js`,
    value: categoriesLinks.nodejs,
    image: `/categories-logos/nodejs.png`,
  },
  {
    title: `Webflow`,
    value: categoriesLinks.webflow,
    image: `/categories-logos/webflow.png`,
  },
];
