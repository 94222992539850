export const categoriesLinks = {
  javascript: `javascript-jobs`,
  react: `reactjs-jobs`,
  vue: `vuejs-jobs`,
  next: `nextjs-jobs`,
  crypto: `crypto-jobs`,
  devops: `devops-jobs`,
  java: `java-jobs`,
  python: `python-jobs`,
  android: `android-jobs`,
  ios: `ios-jobs`,
  nodejs: `nodejs-jobs`,
  webflow: `webflow`,
  // country specific categories
  dutch: `dutch-tech-jobs`,
};

export const categories = [
  {
    title: `Javascript remote jobs, best job board for JS, frontend and backend developers`,
    subtitle: `JS Jobs, Remote Js Jobs, Javascript Jobs and more`,
    categoryLink: categoriesLinks.javascript,
    sidebarLink: categoriesLinks.javascript,
    categoryName: `Javascript`,
    tags: [`javascript`],
    metatags: `js`,
    description: ``,
    ogImage: `https://static.jobshash.com/js-og.png`,
    instagram: `https://www.instagram.com/jobshash/`,
    telegram: `https://t.me/jobs_in_js`,
    twitter: `https://twitter.com/jobs_in_js`,
  },
  {
    title: `React JS remote jobs, best job board for ReactJs developers`,
    subtitle: `React Jobs, Remote React Jobs, React Native Jobs and more`,
    categoryLink: categoriesLinks.react,
    sidebarLink: categoriesLinks.react,
    categoryName: `ReactJS`,
    tags: [`react.js`],
    metatags: `react.js`,
    description: ``,
    ogImage: `https://static.jobshash.com/reactjs-og.png`,
    instagram: `https://www.instagram.com/jobshash/`,
    telegram: `https://t.me/JobsInReactJs`,
    twitter: `https://twitter.com/JobsInReactJs`,
  },
  {
    title: `Vue.js developer jobs, Vue remote jobs, best job board for VueJs developers`,
    subtitle: `Find VueJs developer jobs`,
    categoryLink: categoriesLinks.vue,
    sidebarLink: categoriesLinks.vue,
    categoryName: `Vue.js`,
    tags: [`vue.js`],
    metatags: `vue.js`,
    description: ``,
    ogImage: `https://static.jobshash.com/vuejs-og.png`,
    instagram: `https://www.instagram.com/jobshash/`,
    telegram: `https://t.me/jobs_vuejs`,
    twitter: `https://twitter.com/jobs_vuejs`,
  },
  {
    title: `Next.js developer jobs, NextJs remote jobs, best job board for NextJs developers`,
    subtitle: `Find Next.js developer jobs`,
    categoryLink: categoriesLinks.next,
    sidebarLink: categoriesLinks.next,
    categoryName: `Next.js`,
    tags: [`next.js`],
    metatags: `next.js`,
    description: ``,
    ogImage: `https://static.jobshash.com/nextjs-og.png`,
  },
  {
    title: `Crypto developer jobs, Web3 remote jobs, best job board for blockchain developers`,
    subtitle: `Find Web3/Blockchain developer jobs`,
    categoryLink: categoriesLinks.crypto,
    sidebarLink: categoriesLinks.crypto,
    categoryName: `Crypto`,
    tags: [`crypto`],
    metatags: `crypto`,
    description: ``,
    ogImage: `https://static.jobshash.com/crypto-og.png`,
    instagram: `https://www.instagram.com/jobshash/`,
    telegram: `https://t.me/cryptotechjobs`,
    twitter: `https://twitter.com/Cryptotechjobs`,
  },
  {
    title: `Devops jobs, Devops  remote jobs, best job board for devops`,
    subtitle: `Find Devops jobs`,
    categoryLink: categoriesLinks.devops,
    sidebarLink: categoriesLinks.devops,
    categoryName: `DevOps`,
    tags: [`devops`],
    metatags: `devops`,
    description: ``,
    ogImage: `https://static.jobshash.com/devops-og.png`,
    instagram: `https://www.instagram.com/jobshash/`,
    telegram: `https://t.me/devops_job`,
    twitter: `https://twitter.com/devopsjob`,
  },
  {
    title: `Java remote jobs, best job board for java developers`,
    subtitle: `Find jobs for java and backend developers`,
    categoryLink: categoriesLinks.java,
    sidebarLink: categoriesLinks.java,
    categoryName: `Java`,
    tags: [`java`],
    metatags: `java`,
    description: ``,
    ogImage: `https://static.jobshash.com/java-og.png`,
    instagram: `https://www.instagram.com/jobshash/`,
    telegram: `https://t.me/javatechjobs`,
    twitter: `https://twitter.com/java_techjobs`,
  },
  {
    title: `Python remote jobs, best job board for python developers`,
    subtitle: `Find jobs for python and backend developers`,
    categoryLink: categoriesLinks.python,
    sidebarLink: categoriesLinks.python,
    categoryName: `Python`,
    tags: [`python`],
    metatags: `python`,
    description: ``,
    ogImage: `https://static.jobshash.com/python-og.png`,
    instagram: `https://www.instagram.com/jobshash/`,
    telegram: `https://t.me/pythonjobs2`,
    twitter: `https://twitter.com/pythonjobs2`,
  },
  {
    title: `Android remote jobs, best job board for android developers`,
    subtitle: `Find jobs for android and mobile developers`,
    categoryLink: categoriesLinks.android,
    sidebarLink: categoriesLinks.android,
    categoryName: `Android`,
    tags: [`android`],
    metatags: `android`,
    description: ``,
    ogImage: `https://static.jobshash.com/android-og.png`,
    instagram: `https://www.instagram.com/jobshash/`,
    telegram: `https://t.me/jobsandroiddev`,
    twitter: `https://twitter.com/jobsandroiddev`,
  },
  {
    title: `IOS remote jobs, best job board for ios developers`,
    subtitle: `Find jobs for ios and mobile developers`,
    categoryLink: categoriesLinks.ios,
    sidebarLink: categoriesLinks.ios,
    categoryName: `IOS`,
    tags: [`ios`],
    metatags: `ios`,
    description: ``,
    ogImage: `https://static.jobshash.com/ios-og.png`,
    instagram: `https://www.instagram.com/jobshash/`,
    telegram: `https://t.me/iosjob1`,
    twitter: `https://twitter.com/ios_job`,
  },
  {
    categoryLink: categoriesLinks.nodejs,
    sidebarLink: categoriesLinks.nodejs,
    categoryName: `Node.js`,
    tags: [`node.js`],
    metatags: `nodejs`,
    description: ``,
    ogImage: `https://static.jobshash.com/nodejs-og.png`,
    instagram: `https://www.instagram.com/jobshash/`,
  },
  {
    title: `Webflow remote jobs, best job board for webflow and no-code developers`,
    subtitle: `Find jobs for webflow and no-code developers`,
    categoryLink: categoriesLinks.webflow,
    sidebarLink: categoriesLinks.webflow,
    categoryName: `Webflow`,
    tags: [`webflow`],
    metatags: `webflow1`,
    description: ``,
    ogImage: `https://static.jobshash.com/default-og.png`,
    instagram: `https://www.instagram.com/jobshash/`,
    telegram: `https://t.me/webflow_jobs`,
    twitter: `https://twitter.com/WebflowJobs`,
  },
  // country specific categories
  {
    title: `🇳🇱 Dutch Jobs for developers, jobs in the Netherlands `,
    subtitle: `Find jobs for developers in the Netherlands`,
    categoryLink: categoriesLinks.dutch,
    sidebarLink: categoriesLinks.dutch,
    categoryName: `Dutch Jobs`,
    tags: [`dutch-specific`],
    metatags: `dutch`,
    description: ``,
    ogImage: `https://static.jobshash.com/dutch.png`,
    instagram: `https://www.instagram.com/jobshash/`,
  },
];

export type TCategory = {
  title: string;
  subtitle: string;
  categoryLink: string;
  sidebarLink: string;
  categoryName: string;
  tags: string[];
  metatags: string;
  description: string;
  ogImage: string;
  instagram?: string;
  telegram?: string;
  twitter?: string;
};
